import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  markNotAuthenticated,
  signInUserToken,
} from "./redux/actions/authActions";
import LoadingSpinner from "./components/ui/LoadingSpinner";
import DashboardScreen from "./routes/Dashboard";
import LoginScreen from "./routes/Login";
import ForgotPasswordScreen from "./routes/ForgotPassword";
import RestaurantsScreen from "./routes/Restaurants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "./firebase";
import RestaurantScreen from "./routes/Restaurant";

function App() {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.signedIn === null) {
      const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
        if (user) {
          dispatch(signInUserToken(user.accessToken));
        } else {
          dispatch(markNotAuthenticated());
        }
      });
      unsubscribe();
    }
  }, [auth, dispatch]);

  return (
    <BrowserRouter>
      {auth.signedIn === null ? (
        <div className='flex flex-col w-full h-screen justify-center items-center'>
          <LoadingSpinner />
          <p className='mt-4'>Welcome to Rated Dishes Admin Dashboard</p>
        </div>
      ) : auth.signedIn ? (
        <Routes>
          <Route path='/' element={<DashboardScreen />} />
          <Route path='/restaurants' element={<RestaurantsScreen />} />
          <Route path='/restaurant'>
            <Route path=':restaurantId' element={<RestaurantScreen />} />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route path='/' element={<LoginScreen />} />
          <Route path='/forgot-password' element={<ForgotPasswordScreen />} />
        </Routes>
      )}
      <ToastContainer autoClose={2000} position='bottom-left' />
    </BrowserRouter>
  );
}

export default App;
