import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import { firebaseAuth } from "../../firebase";
import EditRestaurantForm from "./components/EditRestaurantForm";
import NewRestaurantForm from "./components/NewRestaurantForm";
import RestaurantsTable from "./components/RestaurantsTable";

function RestaurantsScreen() {
  const [showNewRestaurantForm, setShowNewRestaurantForm] = useState(false);
  const [showEditRestaurantForm, setShowEditRestaurantForm] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantToEdit, setRestaurantToEdit] = useState(null);

  const cancelEditClickHandler = () => {
    setShowEditRestaurantForm(false);
    setRestaurantToEdit(null);
  };

  useEffect(() => {
    if (restaurants.length === 0) {
      firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/restaurant`,
            {
              headers: {
                Authorization: "Bearer " + idToken,
              },
            }
          );
          if (data) {
            setRestaurants(data);
          } else {
            toast.error("No restaurants found");
          }
        } catch (error) {
          toast.error("Error retrieving restaurants");
        }
      });
    }
  }, [restaurants.length]);
  return (
    <Layout>
      <h2 className='text-xl font-semibold'>Restaurants</h2>
      <div className='mt-2'>
        {!showNewRestaurantForm && !showEditRestaurantForm && (
          <button
            className='px-4 py-2 bg-blue-500 text-white rounded-lg shadow-lg'
            onClick={() => setShowNewRestaurantForm(true)}
          >
            New Restaurant
          </button>
        )}
        {showNewRestaurantForm && (
          <button
            className='px-4 py-2 bg-red-500 text-white rounded-lg shadow-lg'
            onClick={() => setShowNewRestaurantForm(false)}
          >
            Cancel
          </button>
        )}
        {showEditRestaurantForm && (
          <button
            className='px-4 py-2 bg-red-500 text-white rounded-lg shadow-lg'
            onClick={() => cancelEditClickHandler()}
          >
            Cancel
          </button>
        )}
      </div>
      {!showNewRestaurantForm && !showEditRestaurantForm && (
        <RestaurantsTable
          restaurants={restaurants}
          setShowEditRestaurantForm={setShowEditRestaurantForm}
          setRestaurantToEdit={setRestaurantToEdit}
        />
      )}
      {showNewRestaurantForm && (
        <NewRestaurantForm
          restaurants={restaurants}
          setRestaurants={setRestaurants}
        />
      )}
      {showEditRestaurantForm && (
        <EditRestaurantForm
          restaurant={restaurantToEdit}
          setShowEditRestaurantForm={setShowEditRestaurantForm}
          setRestaurantToEdit={setRestaurantToEdit}
        />
      )}
    </Layout>
  );
}

export default RestaurantsScreen;
