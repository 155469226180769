import React from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../components/ui/Table";

function DishTable({ dishes, setShowEditDishForm, setDishToEdit }) {
  const navigate = useNavigate();

  const editDishHandler = (dish) => {
    setDishToEdit(dish);
    setShowEditDishForm(true);
  };

  const columns = [
    {
      title: "Name",
    },
    {
      title: "Category",
    },
    {
      title: "Tags",
    },
    {
      title: "Actions",
    },
  ];

  const rows = dishes.map((dish) => ({
    fields: [
      { value: dish.name, className: "text-left" },
      { value: dish.category },
      {
        value: (
          <div className='flex flex-wrap'>
            {dish.tags?.map((tag) => (
              <p className='mr-2 bg-blue-300 text-xs px-2 rounded-md'>{tag}</p>
            ))}
          </div>
        ),
      },
      {
        value: (
          <div className='space-x-2'>
            <button onClick={() => editDishHandler(dish)}>Edit</button>
            <button onClick={() => navigate("/")}>View</button>
          </div>
        ),
      },
    ],
  }));

  return (
    <div className='bg-white p-8 mt-4 rounded-lg shadow-lg'>
      <Table columns={columns} rows={rows} />
    </div>
  );
}

export default DishTable;
