import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TextField from "../../../components/ui/TextField";
import { firebaseAuth } from "../../../firebase";

function EditRestaurantForm({
  restaurant,
  setShowEditRestaurantForm,
  setRestaurantToEdit,
}) {
  const [editRestaurant, setEditRestaurant] = useState({
    id: "",
    name: "",
    addrOne: "",
    city: "",
    postcode: "",
  });

  const onSaveHandler = (e) => {
    e.preventDefault();
    firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_BACKEND}/restaurant`,
          {
            editRestaurant,
          },
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
          }
        );
        if (data) {
          toast.success("Restaurant saved");
          setShowEditRestaurantForm(false);
          setRestaurantToEdit(null);
        } else {
          toast.error("Error saving restaurant");
        }
      } catch (error) {
        console.log(error);
        toast.error("Error saving restaurant");
      }
    });
  };

  useEffect(() => {
    setEditRestaurant({
      id: restaurant.id,
      name: restaurant.name,
      addrOne: restaurant.addrOne,
      city: restaurant.city,
      postcode: restaurant.postcode,
    });
  }, [restaurant]);

  return (
    <div className='bg-white p-8 mt-4 rounded-lg shadow-lg'>
      <h3 className='text-lg font-semibold'>Edit Restaurant</h3>
      <form className='w-8/12'>
        <TextField
          label='Name'
          value={editRestaurant.name}
          type='text'
          placeholder='Enter name of restaurant'
          onChange={(e) =>
            setEditRestaurant({ ...editRestaurant, name: e.target.value })
          }
        />
        <TextField
          label='Address Line One'
          value={editRestaurant.addrOne}
          type='text'
          placeholder='Enter first line of address'
          onChange={(e) =>
            setEditRestaurant({ ...editRestaurant, addrOne: e.target.value })
          }
        />
        <TextField
          label='City'
          value={editRestaurant.city}
          type='text'
          placeholder='Enter town or city'
          onChange={(e) =>
            setEditRestaurant({ ...editRestaurant, city: e.target.value })
          }
        />
        <TextField
          label='Postcode'
          value={editRestaurant.postcode}
          type='text'
          placeholder='Enter postcode'
          onChange={(e) =>
            setEditRestaurant({ ...editRestaurant, postcode: e.target.value })
          }
        />
        <button
          className='px-4 py-2 bg-blue-500 text-white rounded-lg shadow-lg'
          onClick={(e) => onSaveHandler(e)}
        >
          Save
        </button>
      </form>
    </div>
  );
}

export default EditRestaurantForm;
