import React from "react";

function SelectField({ label, value, placeholder, options, onChange }) {
  return (
    <div className='flex w-full justify-between items-center my-4'>
      <label className='mb-2'>{label}</label>
      <select
        value={value}
        className='p-2 h-10 border rounded-lg w-8/12'
        onChange={onChange}
      >
        <option value='' disabled>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectField;
