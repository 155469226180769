import React from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/ui/Table";

function RestaurantsTable({
  restaurants,
  setShowEditRestaurantForm,
  setRestaurantToEdit,
}) {
  const navigate = useNavigate();

  const editRestaurantHandler = (restaurant) => {
    setRestaurantToEdit(restaurant);
    setShowEditRestaurantForm(true);
  };

  const columns = [
    {
      title: "Name",
    },
    {
      title: "Actions",
    },
  ];
  const rows = restaurants.map((restaurant) => ({
    fields: [
      { value: restaurant.name, className: "text-left" },
      {
        value: (
          <div className='space-x-2'>
            <button onClick={() => editRestaurantHandler(restaurant)}>
              Edit
            </button>
            <button onClick={() => navigate(`/restaurant/${restaurant.id}`)}>
              View
            </button>
          </div>
        ),
      },
    ],
  }));

  return (
    <div className='bg-white p-8 mt-4 rounded-lg shadow-lg'>
      <Table columns={columns} rows={rows} />
    </div>
  );
}

export default RestaurantsTable;
