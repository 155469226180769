import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import SelectField from "../../components/ui/SelectField";
import TextField from "../../components/ui/TextField";
import { firebaseAuth } from "../../firebase";
import { categories } from "../../utils/selectOptions";

function NewDishForm({ dishes, setDishes, restaurant }) {
  const [newTag, setNewTag] = useState("");
  const [newDish, setNewDish] = useState({
    name: "",
    category: "",
    tags: [],
  });

  const addTagHandler = (e) => {
    e.preventDefault();
    setNewDish({ ...newDish, tags: [...newDish.tags, newTag] });
    setNewTag("");
  };

  const removeTagHandler = (e, remove) => {
    e.preventDefault();
    const newTagArray = newDish.tags.filter((tag) => tag !== remove);
    setNewDish({ ...newDish, tags: newTagArray });
  };

  const onSaveHandler = (e) => {
    e.preventDefault();
    firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND}/admin/dish`,
          {
            newDish,
            restaurant,
          },
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
          }
        );
        setDishes([data, ...dishes]);
        toast.success("Dish saved");
        setNewDish({
          name: "",
          category: "",
          tags: [],
        });
      } catch (error) {
        console.log(error);
        toast.error("Error saving dish");
      }
    });
  };

  return (
    <div className='bg-white p-8 mt-4 rounded-lg shadow-lg'>
      <h3 className='text-lg font-sem'>Create Dish</h3>
      <form className='w-8/12'>
        <TextField
          label='Name'
          value={newDish.name}
          type='text'
          placeholder='Enter name of dish'
          onChange={(e) => setNewDish({ ...newDish, name: e.target.value })}
        />
        <SelectField
          label='Category'
          value={newDish.category}
          placeholder='Select category for dish'
          options={categories}
          onChange={(e) => setNewDish({ ...newDish, category: e.target.value })}
        />
        <div className='flex w-full justify-between items-center my-4'>
          <label className='mb-2'>Tags</label>
          <div className='w-8/12'>
            <div className='flex mb-1 flex-wrap'>
              {newDish.tags.map((tag) => (
                <div className='flex space-x-2 bg-blue-200 px-4 rounded-md mr-2 mb-2'>
                  <p className=''>{tag}</p>
                  <button onClick={(e) => removeTagHandler(e, tag)}>x</button>
                </div>
              ))}
            </div>
            <div className='flex'>
              <input
                value={newTag}
                className='p-2 h-10 border rounded-lg w-full'
                onChange={(e) => setNewTag(e.target.value)}
              />
              <button className='p-2 h-10' onClick={(e) => addTagHandler(e)}>
                +
              </button>
            </div>
          </div>
        </div>
        <button
          className='px-4 py-2 bg-blue-500 text-white rounded-lg shadow-lg'
          onClick={(e) => onSaveHandler(e)}
        >
          Save
        </button>
      </form>
    </div>
  );
}

export default NewDishForm;
