import React from "react";
import { Link } from "react-router-dom";

function NavLink({ label, to }) {
  return (
    <Link
      to={to}
      className='bg-white text-center w-full p-4 shadow-lg rounded-lg'
    >
      {label}
    </Link>
  );
}

export default NavLink;
