import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import TextField from "../../../components/ui/TextField";
import { firebaseAuth } from "../../../firebase";

function NewRestaurantForm({ restaurants, setRestaurants }) {
  const [newRestaurant, setNewRestaurant] = useState({
    name: "",
    addrOne: "",
    city: "",
    postcode: "",
  });

  const onSaveHandler = (e) => {
    e.preventDefault();
    firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND}/admin/restaurant`,
          {
            newRestaurant,
          },
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
          }
        );
        setRestaurants([data, ...restaurants]);
        toast.success("Restaurant saved");
        setNewRestaurant({
          name: "",
          addrOne: "",
          city: "",
          postcode: "",
        });
      } catch (error) {
        console.log(error);
        toast.error("Error saving restaurant");
      }
    });
  };
  return (
    <div className='bg-white p-8 mt-4 rounded-lg shadow-lg'>
      <h3 className='text-lg font-semibold'>Create Restaurant</h3>
      <form className='w-full lg:w-8/12'>
        <TextField
          label='Name'
          value={newRestaurant.name}
          type='text'
          placeholder='Enter name of restaurant'
          onChange={(e) =>
            setNewRestaurant({ ...newRestaurant, name: e.target.value })
          }
        />
        <TextField
          label='Address Line One'
          value={newRestaurant.addrOne}
          type='text'
          placeholder='Enter first line of address'
          onChange={(e) =>
            setNewRestaurant({ ...newRestaurant, addrOne: e.target.value })
          }
        />
        <TextField
          label='City'
          value={newRestaurant.city}
          type='text'
          placeholder='Enter town or city'
          onChange={(e) =>
            setNewRestaurant({ ...newRestaurant, city: e.target.value })
          }
        />
        <TextField
          label='Postcode'
          value={newRestaurant.postcode}
          type='text'
          placeholder='Enter postcode'
          onChange={(e) =>
            setNewRestaurant({ ...newRestaurant, postcode: e.target.value })
          }
        />
        <button
          className='px-4 py-2 bg-blue-500 text-white rounded-lg shadow-lg'
          onClick={(e) => onSaveHandler(e)}
        >
          Save
        </button>
      </form>
    </div>
  );
}

export default NewRestaurantForm;
