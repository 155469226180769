import React from "react";
import { useDispatch } from "react-redux";
import { signOutUser } from "../../redux/actions/authActions";

function Header() {
  const dispatch = useDispatch();
  const signOutHandler = () => {
    dispatch(signOutUser());
  };
  return (
    <div className='flex py-2 px-4 bg-slate-200 justify-between items-center'>
      <h1 className='font-bold text-lg'>Rated Dishes Admin Panel</h1>
      <button
        className='px-4 py-2 bg-white rounded-lg shadow-lg'
        onClick={() => signOutHandler()}
      >
        Sign Out
      </button>
    </div>
  );
}

export default Header;
