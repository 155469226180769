import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../firebase";
import DishTable from "./DishTable";
import EditDishForm from "./EditDishForm";
import NewDishForm from "./NewDishForm";

function RestaurantScreen() {
  const [showNewDishForm, setShowNewDishForm] = useState(false);
  const [showEditDishForm, setShowEditDishForm] = useState(false);
  const [dishToEdit, setDishToEdit] = useState(null);
  const [restaurantToView, setRestaurantToView] = useState(null);
  const [dishes, setDishes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { restaurantId } = useParams();

  const cancelEditClickHandler = () => {
    setShowEditDishForm(false);
    setDishToEdit(null);
  };

  useEffect(() => {
    if (!restaurantToView) {
      setLoading(true);
      firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/admin/restaurantId`,
            {
              headers: {
                Authorization: "Bearer " + idToken,
              },
              params: {
                restaurantId,
              },
            }
          );
          if (data) {
            setRestaurantToView(data);
            setDishes(data.restaurantDishes);
          } else {
            toast.error("No campaign found");
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
          toast.error("Error retrieving restaurant details");
        }
      });
    }
  }, [restaurantToView, restaurantId]);

  return (
    <Layout>
      {!restaurantToView && loading && (
        <div className='flex flex-col items-center'>
          <LoadingSpinner />
          <p className='mt-2'>Loading Restaurant...</p>
        </div>
      )}
      {restaurantToView && !loading && (
        <>
          <h3 className='text-lg font-bold'>{restaurantToView.name}</h3>
          <div className='mt-2'>
            {!showNewDishForm && !showEditDishForm && restaurantToView && (
              <button
                className='px-4 py-2 bg-blue-500 text-white rounded-lg shadow-lg'
                onClick={() => setShowNewDishForm(true)}
              >
                New Dish
              </button>
            )}
            {showNewDishForm && (
              <button
                className='px-4 py-2 bg-red-500 text-white rounded-lg shadow-lg'
                onClick={() => setShowNewDishForm(false)}
              >
                Cancel
              </button>
            )}
            {showEditDishForm && (
              <button
                className='px-4 py-2 bg-red-500 text-white rounded-lg shadow-lg'
                onClick={() => cancelEditClickHandler()}
              >
                Cancel
              </button>
            )}
          </div>
          {!showNewDishForm && !showEditDishForm && (
            <DishTable
              dishes={dishes}
              setShowEditDishForm={setShowEditDishForm}
              setDishToEdit={setDishToEdit}
            />
          )}
          {showNewDishForm && (
            <NewDishForm
              dishes={dishes}
              setDishes={setDishes}
              restaurant={restaurantToView}
            />
          )}
          {showEditDishForm && dishToEdit && (
            <EditDishForm
              dishToEdit={dishToEdit}
              setDishToEdit={setDishToEdit}
              setShowEditDishForm={setShowEditDishForm}
              dishes={dishes}
              setDishes={setDishes}
            />
          )}
        </>
      )}
    </Layout>
  );
}

export default RestaurantScreen;
