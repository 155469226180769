import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../components/ui/Button";
import TextField from "../../components/ui/TextField";
import { signInUserEmailAndPassword } from "../../redux/actions/authActions";

function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const signInHandler = () => {
    dispatch(signInUserEmailAndPassword(email, password));
  };

  return (
    <div className='flex flex-col min-h-screen items-center justify-center px-4'>
      <div className='flex flex-col w-full lg:w-3/12'>
        <TextField
          label='Email Address'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label='Password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button label='Sign In' onClick={() => signInHandler()} />
      </div>
      <p className='mt-4'>
        Forgot Password?{" "}
        <a href='/forgot-password' className='text-blue-500 underline'>
          Click Here
        </a>
      </p>
    </div>
  );
}

export default LoginScreen;
