import React from "react";
import Layout from "../../components/layout";

function DashboardScreen() {
  return (
    <Layout>
      <p>I am dashboard</p>
    </Layout>
  );
}

export default DashboardScreen;
