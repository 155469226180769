export const categories = [
  {
    value: "Starter",
    label: "Starter",
  },
  {
    value: "Breakfast",
    label: "Breakfast",
  },
  {
    value: "Main",
    label: "Main",
  },
  {
    value: "Dessert",
    label: "Dessert",
  },
  {
    value: "Small Plate",
    label: "Small Plate",
  },
  {
    value: "Sharing Platter",
    label: "Sharing Platter",
  },
];
