import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SelectField from "../../components/ui/SelectField";
import TextField from "../../components/ui/TextField";
import { firebaseAuth } from "../../firebase";
import { categories } from "../../utils/selectOptions";

function EditDishForm({
  dishToEdit,
  setDishToEdit,
  setShowEditDishForm,
  dishes,
  setDishes,
}) {
  const [newTag, setNewTag] = useState("");
  const [editDish, setEditDish] = useState({
    id: "",
    name: "",
    category: "",
    tags: [],
  });

  const addTagHandler = (e) => {
    e.preventDefault();
    setEditDish({ ...editDish, tags: [...editDish.tags, newTag] });
    setNewTag("");
  };

  const removeTagHandler = (e, remove) => {
    e.preventDefault();
    const newTagArray = editDish.tags.filter((tag) => tag !== remove);
    setEditDish({ ...editDish, tags: newTagArray });
  };

  const onSaveHandler = (e) => {
    e.preventDefault();
    firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_BACKEND}/admin/dish`,
          {
            editDish,
          },
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
          }
        );
        if (data) {
          toast.success("Dish updated");
          const existingDishes = dishes.filter(
            (dish) => dish.id !== editDish.id
          );
          setDishes([...existingDishes, data]);
          setShowEditDishForm(false);
          setDishToEdit(false);
        } else {
          toast.error("Error updating dish");
        }
      } catch (error) {
        console.log(error);
        toast.error("Error updating dish");
      }
    });
  };

  useEffect(() => {
    setEditDish({
      id: dishToEdit.id,
      name: dishToEdit.name,
      category: dishToEdit.category,
      tags: dishToEdit.tags ? dishToEdit.tags : [],
    });
  }, [dishToEdit]);

  return (
    <div className='bg-white p-8 mt-4 rounded-lg shadow-lg'>
      <h3 className='text-lg font-sem'>Update Dish</h3>
      <form className='w-8/12'>
        <TextField
          label='Name'
          value={editDish.name}
          type='text'
          placeholder='Enter name of dish'
          onChange={(e) => setEditDish({ ...editDish, name: e.target.value })}
        />
        <SelectField
          label='Category'
          value={editDish.category}
          placeholder='Select category for dish'
          options={categories}
          onChange={(e) =>
            setEditDish({ ...editDish, category: e.target.value })
          }
        />
        <div className='flex w-full justify-between items-center my-4'>
          <label className='mb-2'>Tags</label>
          <div className='w-8/12'>
            <div className='flex mb-1 flex-wrap'>
              {editDish.tags.map((tag) => (
                <div className='flex space-x-2 bg-blue-200 px-4 rounded-md mr-2 mb-2'>
                  <p className=''>{tag}</p>
                  <button onClick={(e) => removeTagHandler(e, tag)}>x</button>
                </div>
              ))}
            </div>
            <div className='flex'>
              <input
                value={newTag}
                className='p-2 h-10 border rounded-lg w-full'
                onChange={(e) => setNewTag(e.target.value)}
              />
              <button className='p-2 h-10' onClick={(e) => addTagHandler(e)}>
                +
              </button>
            </div>
          </div>
        </div>
        <button
          className='px-4 py-2 bg-blue-500 text-white rounded-lg shadow-lg'
          onClick={(e) => onSaveHandler(e)}
        >
          Save
        </button>
      </form>
    </div>
  );
}

export default EditDishForm;
